import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Button, EditIcon, PlusIcon, TrashIcon, toaster } from 'evergreen-ui';
import { getCreditsByCustomer } from '../../services/OrderManagementService';
import IssueOrEditCreditsModal from './credits/IssueOrEditCreditsModal';
import DeleteCreditModal from './credits/DeleteCreditModal';

const CustomerCredits = ({ customerId, setErrorMessage, customerEmail }) => {
  const [credits, setCredits] = useState(null);
  const [creditToEdit, setCreditToEdit] = useState(null);
  const [isCreatingCredit, setIsCreatingCredit] = useState(false);
  const [creditToDelete, setCreditToDelete] = useState(null);

  const getCredits = async () => {
    if (customerId) {
      const result = await getCreditsByCustomer(customerId);
      if (result.credits) {
        setCredits(result.credits);
      } else {
        setErrorMessage('Unable to retrieve credits. Please wait and try again.');
      }
    }
  };

  useEffect(() => {
    getCredits();
  }, [customerId, setErrorMessage]);

  const successActionsForCreatingOrEditingCredit = async (isCreate) => {
    await getCredits();
    toaster.success(`Successfully ${isCreate ? 'created' : 'edited'} credit.`);
  };

  const successActionsForDeletingCredit = async () => {
    await getCredits();
    toaster.success('Successfully deleted credit.');
  };

  return (
    <div className="card">
      <div className="card-header">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ justifyContent: 'left' }}>
            <h4 className="card-header-title mt-2">Credits</h4>
          </div>
          <div style={{ justifyContent: 'right' }}>
            <Button intent="success" iconAfter={PlusIcon} onClick={() => setIsCreatingCredit(true)}>
              Issue Credits
            </Button>
          </div>
        </div>
      </div>
      <div className="card-body">
        {credits &&
          credits
            .filter((credit) => credit.remaining_amount > 0 && credit.credit_amount > 0)
            .map((credit, index) => {
              return (
                <div key={`credit${credit.id}`}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ justifyContent: 'left' }}>
                      <div>Id: {credit.id}</div>
                      <div>Amount: {credit.credit_amount}</div>
                      <div>Remaining Amount: {credit.remaining_amount}</div>
                      <div>Issued Date: {DateTime.fromISO(credit.createdAt).toFormat('MMM d yyyy')}</div>
                    </div>
                    <div style={{ justifyContent: 'right' }}>
                      <Button intent="none" iconAfter={EditIcon} onClick={() => setCreditToEdit(credit)}>
                        Edit
                      </Button>
                      <Button intent="danger" iconAfter={TrashIcon} className="ml-2" onClick={() => setCreditToDelete(credit)}>
                        Delete
                      </Button>
                    </div>
                  </div>
                  <div>Reason: {credit.reason}</div>
                  {index !== credits.length - 1 && <hr />}
                </div>
              );
            })}
      </div>

      {creditToEdit && (
        <IssueOrEditCreditsModal
          credit={creditToEdit}
          customerEmail={customerEmail}
          closeModal={() => setCreditToEdit(null)}
          successActions={() => successActionsForCreatingOrEditingCredit(false)}
        />
      )}
      {isCreatingCredit && (
        <IssueOrEditCreditsModal
          customerEmail={customerEmail}
          closeModal={() => setIsCreatingCredit(false)}
          successActions={() => successActionsForCreatingOrEditingCredit(true)}
        />
      )}
      {creditToDelete && (
        <DeleteCreditModal credit={creditToDelete} closeModal={() => setCreditToDelete(null)} successActions={successActionsForDeletingCredit} />
      )}
    </div>
  );
};

CustomerCredits.propTypes = {
  customerId: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  customerEmail: PropTypes.string.isRequired,
};

export default CustomerCredits;
