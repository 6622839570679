import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { toaster } from 'evergreen-ui';
import getSelectedInventoryRecordData from './inventory-record/common/getSelectedInventoryRecordData';
import { LookupContext } from '../../../context/lookupContext';
import InventoryRecordContent from './inventory-record/InventoryRecordContent';
import { FcContext } from '../../../context/fcContext';

const InventoryRecordPage = () => {
  const { productFeed } = useContext(LookupContext);
  const { allFcs } = useContext(FcContext);

  const { id: requestId } = useParams();

  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedLot, setSelectedLot] = useState({
    adjustmentsSummary: [],
    adjustments: [],
    changelogs: [],
    imageUrl: '',
    externalLotId: '',
    internalLotId: '',
    productName: '',
    classification: '',
    details: [],
  });

  const instantiateInstanceVariablesFromId = async () => {
    const selectedInventoryRecordData = await getSelectedInventoryRecordData(requestId, productFeed);

    if (!selectedInventoryRecordData) {
      setErrorMessage(`No lot found with ID ${requestId}`);
    } else {
      setErrorMessage(null);
      setSelectedLot(selectedInventoryRecordData);
    }
  };

  useEffect(() => {
    if (requestId && requestId.length) {
      instantiateInstanceVariablesFromId();
    } else {
      setErrorMessage('You likely navigated to this page by accident');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  return errorMessage ? (
    <div>{errorMessage}</div>
  ) : (
    <InventoryRecordContent
      makeInventoryRecordModalVisible={() => {}}
      selectedLot={selectedLot}
      fc={allFcs.find((fc) => fc.value === selectedLot?.fcId?.toString()) || {}}
      completeCreatingOrEditingAdjustment={({ successFunctionMessage }) => {
        instantiateInstanceVariablesFromId();
        toaster.success(successFunctionMessage);
      }}
      showCopyLinkIcon={false}
    />
  );
};

export default InventoryRecordPage;
