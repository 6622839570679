/* eslint-disable no-restricted-syntax */
import { DateTime } from 'luxon';
import React, { useContext, useState, useEffect, useReducer } from 'react';
import Select from 'react-select';
import { Checkbox, Dialog, Spinner } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { getInventory } from '../../services/AggregateService';
import { replaceLineItemInSuborder } from '../../services/OrderManagementService';
import { AuthContext } from '../../context/authContext';
import { LookupContext } from '../../context/lookupContext';
import { roleChecks } from '../../utilities/Role';
import Progress from '../common/Progress';
import TextInput from '../common/TextInput';

// eslint-disable-next-line import/no-cycle
import { deallocateStrategies } from './ViewOrder';
import { createProductOption } from './helpers/createProductOption';
import { getOpsNameText } from './helpers/getOpsProductName';
import { updateLineItemReasons } from './helpers/getReasonType';
import { getProductSkusArrayForInventoryLookup } from './helpers/getProductSkusArrayForInventoryLookup';
import { addNonHandledProductComponentsToProductOptions } from './helpers/addNonHandledProductComponentsToProductOptions';

const UpgradeLineItem = ({ suborderDetails, oldLineItem, fcId, fulfillmentDate, showUpgradeLineItem, setShowUpgradeLineItem, onComplete }) => {
  const [inventoryBySku, setInventoryBySku] = useState(null);
  const [inventoryIsLoading, setInventoryIsLoading] = useState(true);
  const [productOptions, setProductOptions] = useState(null);
  const [newLineItem, setNewLineItem] = useState(null);
  const [newLineItemQuantity, setNewLineItemQuantity] = useState('1');
  const [newLineItemDisabled, setNewLineItemDisabled] = useState(true);
  const [availableLineItemsToRemove, setAvailableLineItemsToRemove] = useState([]);
  const [lineItemToRemove, setLineItemToRemove] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allPriceData, setAllPriceData] = useState(null);
  const [deleteReason, setDeleteReason] = useState(null);
  const [upgradeReason, setUpgradeReason] = useState(null);
  const [updateTotals] = useState(false);
  const [deallocateStrategy, setDeallocateStrategy] = useState('hold');
  const [ignoreInventoryAvailability, setIgnoreInventoryAvailability] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [showClose, setShowClose] = useState(false);
  const [totalToSave, setTotalToSave] = useState(0);
  const [saveFailures, setSaveFailures] = useState([]);
  const [totalSaved, totalSavedDispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'increment':
        return state + 1;
      case 'reset':
        return 0;
      default:
        // eslint-disable-next-line consistent-return, no-useless-return
        return;
    }
  }, 0);

  const { userGroups } = useContext(AuthContext);
  const { productFeed } = useContext(LookupContext);

  const setLineItemToReplace = (lineItem) => {
    setLineItemToRemove(lineItem);
    setNewLineItemDisabled(false);
  };

  useEffect(() => {
    const subordersPastBatched = suborderDetails
      .filter((s) => {
        return ['Received', 'Batched'].indexOf(s.status) === -1;
      })
      .map((s) => s.suborderId);

    if (subordersPastBatched.length > 0) {
      const newError = `Invalid suborder status for suborders: ${subordersPastBatched.join(' ,')}`;
      setErrorMessage(newError);
    }
  }, [suborderDetails]);

  useEffect(() => {
    const findOverlap = () => {
      const suborderIds = [];
      const allLineItems = {}; // quantity-sku: [suborderId1, suborderId2]
      const subordersLiPriceData = [];

      suborderDetails.forEach((s) => {
        suborderIds.push(s.suborderId);

        // track lineitem prices from different suborders
        s.lineItems.forEach((li) => {
          const priceData = {
            suborderId: s.suborderId,
            liSku: li.sku,
            liQuantity: li.quantity,
            liPrice: li.price,
          };
          subordersLiPriceData.push(priceData);

          const name = productFeed[li.sku].opsName;

          const lineItemDistinguisher = `${li.quantity}x ${name}_${li.sku}`;

          // create options for lineitems to delete
          if (allLineItems[lineItemDistinguisher]) {
            allLineItems[lineItemDistinguisher].push(s.suborderId);
          } else {
            allLineItems[lineItemDistinguisher] = [s.suborderId];
          }
        });
      });

      setAllPriceData(subordersLiPriceData);

      const lineItemOverlap = [];

      const allSubordersContainLineItem = (liDistinguisher) => {
        return suborderIds.every((s) => {
          return allLineItems[liDistinguisher].indexOf(s) !== -1;
        });
      };

      for (const liDistinguisher in allLineItems) {
        if (allSubordersContainLineItem(liDistinguisher)) {
          lineItemOverlap.push({
            label: liDistinguisher.split('_')[0],
            value: {
              sku: liDistinguisher.split('_')[1],
              quantity: Number(liDistinguisher.split('x ')[0]),
            },
          });
        }
      }

      setAvailableLineItemsToRemove(lineItemOverlap);

      if (lineItemOverlap.length === 0) {
        setErrorMessage('No common line items found');
      }
    };

    if (lineItemToRemove) {
      setNewLineItemQuantity(lineItemToRemove.quantity.toString());
    } else {
      findOverlap();
    }
  }, [lineItemToRemove, suborderDetails]);

  useEffect(() => {
    if (oldLineItem) {
      setLineItemToReplace(oldLineItem);
    }
  }, [oldLineItem]);

  useEffect(() => {
    const getInventoryBySkus = async () => {
      const fulfillmentDateString = fulfillmentDate.split('T')[0];
      const result = await getInventory(
        [fcId],
        getProductSkusArrayForInventoryLookup({
          productFeed,
          canViewFulfillableProductsFromProductList: roleChecks.canViewFulfillableProductsFromProductList(userGroups),
        }),
        fulfillmentDateString,
      );

      if (result.success) {
        const skuInventory = {};

        const { stock } = result;

        for (const sku in stock) {
          if (stock[sku][fcId]) {
            skuInventory[sku] = stock[sku][fcId][fulfillmentDateString];
          }
        }

        setInventoryBySku(skuInventory);
      }
    };

    if (productFeed && Object.keys(productFeed).length && showUpgradeLineItem && fcId && fulfillmentDate && userGroups) {
      getInventoryBySkus();
    }
  }, [productFeed, showUpgradeLineItem, fcId, fulfillmentDate, userGroups]);

  useEffect(() => {
    const buildProductOptions = async () => {
      const suborder = suborderDetails[0]; // to update for bulk action work

      // check if suborder already contains a [non deleted] primary product and/or vase
      // apply these checks excluding the line item to delete
      const hasPrimaryProduct = !!suborder.lineItems.filter(
        (lineItem) => lineItem.isPrimaryProduct && !lineItem.isDeleted && lineItem.sku !== lineItemToRemove.sku,
      ).length;
      const hasVase = !!suborder.lineItems.filter((lineItem) => {
        return (
          (lineItem.sku.startsWith('FLRL-V') ||
            (lineItem.components && lineItem.components.some((component) => component.sku.startsWith('FLRL-V')))) &&
          !lineItem.isDeleted &&
          lineItem.sku !== lineItemToRemove.sku
        );
      }).length;

      const productOptionsAllowed = []; // allowedTransportMode
      const productOptionsOther = [];
      const fulfillableProductComponents = [];
      const handledSkus = new Set();

      const suborderStartTime = DateTime.fromISO(suborder.startTime);

      const bypassChecks = roleChecks.canViewFulfillableProductsFromProductList(userGroups);

      // how products with same FC, available on suborder start date.
      // Filter out primary product if there is one in suborer already, separate by transport mode
      Object.values(productFeed).forEach((product) => {
        const productFromTime = DateTime.fromISO(product.availableForDeliveryFrom);
        const productToTime = DateTime.fromISO(product.availableForDeliveryTo);
        const componentsIncludeVase = product.kit && product.kit.some((component) => component.sku.startsWith('FLRL-V'));
        const passesPrimaryRules =
          (!hasPrimaryProduct || !product.isPrimary) && (!hasVase || !(product.sku.startsWith('FLRL-V') || componentsIncludeVase));
        const passesSecondaryRules =
          suborderStartTime.diff(productFromTime).toObject().milliseconds >= 0 && productToTime.diff(suborderStartTime).toObject().milliseconds > 0;

        if (bypassChecks || passesPrimaryRules) {
          if (bypassChecks || passesSecondaryRules) {
            const opsName = getOpsNameText(productFeed, product);

            const option = createProductOption(product, inventoryBySku, userGroups, opsName);
            if (!option) {
              return;
            }

            if (suborderDetails.length === 0 && product.allowedTransportModes.includes(suborder.transportMode)) {
              productOptionsAllowed.push(option);
            } else {
              // disable selecting a product that isn’t allowed in the current transport mode if user is not CareManager or OpsManager
              if (!roleChecks.canSelectNewProductOption(userGroups)) {
                option.isDisabled = true;
              }
              productOptionsOther.push(option);
            }
          }
        }

        handledSkus.add(product.sku);
      });

      addNonHandledProductComponentsToProductOptions(productFeed, handledSkus, inventoryBySku, userGroups, fulfillableProductComponents);

      const loadedOptions = [
        {
          label: 'Allowed transport mode',
          options: productOptionsAllowed,
        },
        {
          label: 'Other',
          options: [...productOptionsOther, ...fulfillableProductComponents],
        },
      ];

      setProductOptions(loadedOptions);
    };

    if (lineItemToRemove && suborderDetails && suborderDetails.length > 0 && inventoryBySku && Object.values(inventoryBySku).length) {
      buildProductOptions().then(() => {
        setInventoryIsLoading(false);
      });
    }
  }, [inventoryBySku, productFeed, suborderDetails, userGroups, lineItemToRemove, fcId]);

  const handleUpgradeLineItem = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShowClose(false);

    const oldLineItemToUpgrade = {
      sku: lineItemToRemove.sku,
      quantity: lineItemToRemove.quantity,
      reasons: [
        {
          tag: deleteReason.categoryLabel ? `${deleteReason.categoryLabel}: ${deleteReason.value}` : deleteReason.value,
        },
      ],
    };

    if (newLineItemQuantity) {
      newLineItem.quantity = newLineItemQuantity;
    }

    setSaveFailures([]);
    setTotalToSave(suborderDetails.length);

    let countdown = suborderDetails.length;
    const failures = [];
    const promises = [];
    /* eslint-disable no-loop-func */
    for (const suborder of suborderDetails) {
      const actionInLoop = async () => {
        // find old lineitem price
        let oldLineItemPrice = null;

        if (lineItemToRemove.price) {
          oldLineItemPrice = lineItemToRemove.price;
        } else {
          oldLineItemPrice = allPriceData.find((p) => {
            return p.liSku === lineItemToRemove.sku && p.liQuantity === lineItemToRemove.quantity && p.suborderId === suborder.suborderId;
          })?.liPrice;
        }

        const reasons = [
          {
            tag: upgradeReason.categoryLabel ? `${upgradeReason.categoryLabel}: ${upgradeReason.value}` : upgradeReason.value,
          },
        ];

        try {
          // eslint-disable-next-line no-await-in-loop
          const res = await replaceLineItemInSuborder(
            suborder.suborderId,
            newLineItem,
            reasons,
            oldLineItemToUpgrade,
            oldLineItemPrice,
            updateTotals,
            deallocateStrategy,
            ignoreInventoryAvailability,
          );

          if (!res.success) {
            failures.push({ id: suborder.suborderId, ...res });
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          failures.push({ id: suborder.suborderId, error: err.message, success: false });
        }

        totalSavedDispatch({ type: 'increment' });
        countdown--;
        if (!countdown) {
          if (failures.length) {
            setSaveFailures(failures);
          }
          setShowClose(true);
          setLoading(false);
          setTimeout(() => {
            setTotalToSave(0);
            totalSavedDispatch({ type: 'reset' });
            if (!failures.length) {
              onComplete();
            }
          }, 2000);
        }
      };

      promises.push(actionInLoop());
    }

    await Promise.all(promises);
  };

  const disableSave = !(newLineItem && newLineItemQuantity && lineItemToRemove && deleteReason && upgradeReason && !saveFailures.length);

  return (
    <Dialog
      isShown={showUpgradeLineItem}
      title="Upgrade Line Item"
      onCloseComplete={() => setShowUpgradeLineItem(false)}
      hasFooter={false}
      confirmLabel="Update"
      hasClose={!loading}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
    >
      <div>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <div className="row">
          <div className="mb-3">
            <p>For suborders: {suborderDetails.map((suborder) => suborder.suborderId).join(', ')} </p>

            {!oldLineItem ? (
              <div>
                Select Item to Upgrade:
                <Select
                  id="selectLineItemToRemove"
                  onChange={(option) => setLineItemToReplace(option.value)}
                  options={availableLineItemsToRemove}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                />
              </div>
            ) : (
              <div>
                Upgrading line item: {oldLineItem.quantity}x {oldLineItem.name}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            Select reason for upgrading line item:
            <Select
              id="selectReasonForDelete"
              onChange={(option) => {
                setUpgradeReason(option);
                setDeleteReason(option);
              }}
              options={updateLineItemReasons}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            Select new product:
            <Select
              id="selectNewProduct"
              isDisabled={newLineItemDisabled || inventoryIsLoading}
              onChange={(event) => setNewLineItem(event.value)}
              options={productOptions}
              noOptionsMessage={() => 'No inventory for FC and date'}
              placeholder={
                !productOptions || inventoryIsLoading ? (
                  <span>
                    <Spinner size={16} display="inline-block" />
                    Loading...
                  </span>
                ) : (
                  'Select...'
                )
              }
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <div className="row">
          <div className="mb-1">
            <TextInput
              label="Quantity of new product to add"
              onChange={(e) => setNewLineItemQuantity(e.target.value)}
              name="selectNewLineItemQuantity"
              value={newLineItemQuantity}
              validate={(val) => parseInt(val, 10) && Number(val) > 0}
            />
          </div>
        </div>
        {/* Temporarily hide this option for VDay
          <div className="row">
            <div className="mb-3">
              <Checkbox
                label="Subtract the price of these items from order"
                checked={updateTotals}
                onChange={e => setUpdateTotals(e.target.checked)} />
            </div>
          </div> */}
        <div className="row">
          <div className="mb-3">
            What should we do with the old inventory?
            <Select
              id="selectDallocateType"
              defaultValue={deallocateStrategies.find((x) => x.value === deallocateStrategy)}
              onChange={(event) => setDeallocateStrategy(event.value)}
              options={deallocateStrategies}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <div className="row">
          <div className="mb-3">
            <Checkbox
              label="Ignore Inventory Availability?"
              checked={ignoreInventoryAvailability}
              onChange={(e) => setIgnoreInventoryAvailability(e.target.checked)}
            />
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-end">
            {!showClose && (
              <button type="submit" className="btn btn-primary mx-1" disabled={disableSave} onClick={handleUpgradeLineItem}>
                Update
              </button>
            )}
            {showClose && (
              <button type="button" className="btn btn-secondary" onClick={() => setShowUpgradeLineItem(false)}>
                Close
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="mt-2 mx-3">
        {totalToSave > 0 && <Progress percentage={Math.ceil(totalSaved / totalToSave) * 100} />}

        {totalToSave > 0 && totalToSave === totalSaved && !saveFailures.length && <p className="text-success">Done!</p>}

        {!!saveFailures.length && (
          <div>
            <p className="text-danger mt-4">Update Failures</p>
            <ol>
              {saveFailures.map((f) => (
                <li className="text-danger" key={f.id}>{`${f.id}: ${f.error || f.message}`}</li>
              ))}
            </ol>
          </div>
        )}
      </div>
    </Dialog>
  );
};

UpgradeLineItem.propTypes = {
  suborderDetails: PropTypes.array.isRequired,
  oldLineItem: PropTypes.object,
  fcId: PropTypes.number.isRequired,
  fulfillmentDate: PropTypes.string.isRequired,
  showUpgradeLineItem: PropTypes.bool.isRequired,
  setShowUpgradeLineItem: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

UpgradeLineItem.defaultProps = {
  oldLineItem: null,
};

export default UpgradeLineItem;
