import React from 'react';
import { Textarea } from 'evergreen-ui';
import PropTypes from 'prop-types';

const ChangeLogs = ({ changelogs }) => {
  return (
    <Textarea
      minHeight={300}
      placeholder="There seems to be no logs..."
      value={changelogs}
      style={{
        overflowY: 'auto',
      }}
      disabled
    />
  );
};

ChangeLogs.propTypes = {
  changelogs: PropTypes.string.isRequired,
};

export default ChangeLogs;
