const ORDER_MANAGEMENT_SERVICE_BASE_URL = process.env.REACT_APP_OMS_API_URL;
const CALLBACK_URL = process.env.REACT_APP_REDIRECT_CALLBACK;

const BRAINTREE_API = process.env.REACT_APP_BRAINTREE_API;
const BRAINTREE_MERCHANT_ID = process.env.REACT_APP_BRAINTREE_MERCHANT_ID;
const BRAINTREE_DEFAULT_TOKEN = process.env.REACT_APP_BRAINTREE_DEFAULT_TOKEN;

const AFFIRM_API = process.env.REACT_APP_AFFIRM_API;

const AGGREGATE_URL = process.env.REACT_APP_AGGREGATE_URL;
const AGGREGATE_KEY = process.env.REACT_APP_AGGREGATE_KEY;
const AGGREGATE_SITE_KEY = process.env.REACT_APP_AGGREGATE_SITE_KEY;

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
const STICKY_URL = process.env.REACT_APP_STICKY_URL;

const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;

const ONFLEET_KEY = process.env.REACT_APP_ONFLEET_KEY;
const ONFLEET_URL = process.env.REACT_APP_ONFLEET_URL;

const BULK_AUTO_FOLLOW_THRESHOLD = process.env.REACT_APP_BULK_AUTO_FOLLOW_THRESHOLD;
const BULK_SLACK_CHANNEL = process.env.REACT_APP_BULK_SLACK_CHANNEL;

const NON_PROD_ENVIRONMENT_COLOR = process.env.REACT_APP_DEVELOPMENT_COLOR || 'dimgray';

const PICK_AND_PASS_FEATURE_FLAG = process.env.REACT_APP_PICK_AND_PASS_FEATURE_FLAG || false;

const USE_TRANSACTION_SUMMARY = process.env.REACT_APP_USE_TRANSACTION_SUMMARY || false;

const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID;

const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

const INVENTORY_TABLE_FEATURE_FLAG = process.env.REACT_APP_INVENTORY_TABLE_FEATURE_FLAG || false;
const DELIVERYAREA_TABLE_FEATURE_FLAG = process.env.REACT_APP_DELIVERYAREA_TABLE_FEATURE_FLAG || false;
const DELIVERYWINDOWS_TABLE_FEATURE_FLAG = process.env.REACT_APP_DELIVERYWINDOWS_TABLE_FEATURE_FLAG || false;

const NETSUITE_URL = process.env.REACT_APP_NETSUITE_URL || '';

export {
  ORDER_MANAGEMENT_SERVICE_BASE_URL,
  CALLBACK_URL,
  BRAINTREE_API,
  BRAINTREE_MERCHANT_ID,
  BRAINTREE_DEFAULT_TOKEN,
  AFFIRM_API,
  AGGREGATE_URL,
  AGGREGATE_KEY,
  AGGREGATE_SITE_KEY,
  ENVIRONMENT_NAME,
  ADMIN_URL,
  STICKY_URL,
  ONFLEET_KEY,
  ONFLEET_URL,
  BULK_AUTO_FOLLOW_THRESHOLD,
  BULK_SLACK_CHANNEL,
  NON_PROD_ENVIRONMENT_COLOR,
  PICK_AND_PASS_FEATURE_FLAG,
  USE_TRANSACTION_SUMMARY,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  INVENTORY_TABLE_FEATURE_FLAG,
  NETSUITE_URL,
  DELIVERYAREA_TABLE_FEATURE_FLAG,
  DELIVERYWINDOWS_TABLE_FEATURE_FLAG,
};
