import React, { useState, useContext, useEffect } from 'react';
import { DateTime } from 'luxon';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import { PlusCircle } from 'react-bootstrap-icons';
import Select from 'react-select';
import { Checkbox, Dialog } from 'evergreen-ui';
import { LookupContext } from '../../context/lookupContext';
import { AuthContext } from '../../context/authContext';
import { addNewSuborderToOrder, getOrderInventoryAdjustments } from '../../services/OrderManagementService';
import { getInventory } from '../../services/AggregateService';
import { roleChecks } from '../../utilities/Role';
import Suborder from './Suborder';
import Recipient from './Recipient';
import Message from './Message';
import TransactionSummary from './TransactionSummary';
import PaymentSummary from '../PaymentSummary';
import { ViewSubscription } from './ViewSubscription';
import Customer from './Customer';
import { createProductOption } from './helpers/createProductOption';
import { getOpsNameText } from './helpers/getOpsProductName';
import { updateLineItemReasons } from './helpers/getReasonType';
import TextInput from '../common/TextInput';
import { USE_TRANSACTION_SUMMARY } from '../../config';
import CareResolutionNotification from './CareResolutionNotification';

const OrderDetail = ({ order, setOrder, queryCache, setShowAuditLog, setDefaultAuditLogSelect, canChargeOrder, setShowChargeOrder }) => {
  const [showAddSuborder, setShowAddSuborder] = useState(false);
  const [newSuborder, setNewSuborder] = useState(null);
  const [newSuborderIsCharged, setNewSuborderIsCharged] = useState(false);
  const [orderAdjustments, setOrderAdjustments] = useState({});
  const [inventoryBySku, setInventoryBySku] = useState(null);
  const [productOptions, setProductOptions] = useState(null);
  const [addSuborderReason, setAddSuborderReason] = useState(null);
  const [addSuborderDetails, setAddSuborderDetails] = useState('');

  const suborder = path(['suborders', '0'], order) || {};

  const subordersCanUpdate = order.suborders.filter((s) =>
    ['Received', 'Batched', 'BatchWorkorderPrinted', 'Picking', 'PickedAndQCd', 'AtTable', 'NotecardPrinted'].includes(s.status),
  );

  const addSuborderReasons = updateLineItemReasons;

  const customer = order.customer;

  const { productFeed } = useContext(LookupContext);
  const { userGroups } = useContext(AuthContext);

  useEffect(() => {
    const getInventoryAdjustments = async () => {
      const result = await getOrderInventoryAdjustments(order.orderId);

      if (result.success && result.adjustments) {
        setOrderAdjustments(result.adjustments);
      }
    };

    getInventoryAdjustments();
  }, [order.orderId]);

  useEffect(() => {
    const getInventoryBySkus = async () => {
      const fulfillmentDateString = suborder.fulfillmentDate;
      const result = await getInventory([suborder.fcId], Object.keys(productFeed), fulfillmentDateString); // returns {sku: {fc: {date: stock}}}

      if (result.success) {
        const skuInventory = {};

        const stock = result.stock;

        for (const sku in stock) {
          if (stock[sku][suborder.fcId]) {
            skuInventory[sku] = stock[sku][suborder.fcId][fulfillmentDateString];
          }
        }

        setInventoryBySku(skuInventory);
      }
    };

    if (showAddSuborder) {
      getInventoryBySkus();
    }
  }, [productFeed, showAddSuborder, suborder.fcId, suborder.fulfillmentDate]);

  useEffect(() => {
    const buildProductOptions = () => {
      let productOptionsAllowed = []; // allowedTransportMode
      let productOptionsOther = [];

      const suborderStartTime = DateTime.fromISO(order.suborders[0].delivery.startTime);

      // only show products with same FC and available on suborder start date, filtered by transport mode
      Object.values(productFeed).forEach((product) => {
        const productFromTime = DateTime.fromISO(product.availableForDeliveryFrom);
        const productToTime = DateTime.fromISO(product.availableForDeliveryTo);

        if (
          suborderStartTime.diff(productFromTime).toObject().milliseconds >= 0 &&
          productToTime.diff(suborderStartTime).toObject().milliseconds > 0
        ) {
          let opsName = getOpsNameText(productFeed, product);
          const option = createProductOption(product, inventoryBySku, userGroups, opsName);
          if (!option) {
            return;
          }

          if (product.allowedTransportModes.includes(order.suborders[0].delivery.transportMode)) {
            productOptionsAllowed.push(option);
          } else {
            // disable selecting a product that isn’t allowed in the current transport mode if user is not CareManager or OpsManager
            if (!roleChecks.canSelectNewProductOption(userGroups)) {
              option.isDisabled = true;
            }
            productOptionsOther.push(option);
          }
        }
      });

      setProductOptions([
        {
          label: 'Allowed transport mode',
          options: productOptionsAllowed,
        },
        {
          label: 'Other',
          options: productOptionsOther,
        },
      ]);
    };

    buildProductOptions();
  }, [inventoryBySku, order.suborders, productFeed, suborder.lineItems, userGroups]);

  const addNewSuborder = () => {
    setShowAddSuborder(true);
  };

  const handleAddSuborder = async () => {
    if (newSuborder) {
      const reasons = [
        {
          tag: addSuborderReason.categoryLabel ? `${addSuborderReason.categoryLabel}: ${addSuborderReason.value}` : addSuborderReason.value,
          details: addSuborderDetails ? addSuborderDetails : null,
        },
      ];

      const result = await addNewSuborderToOrder(order.orderId, newSuborder, reasons, newSuborderIsCharged);

      queryCache.invalidateQueries(['orders', order.orderId]);
      setShowAddSuborder(false);
    }
  };

  const getPaymentSummaryComponent = () => {
    const orderTotals = !!order.totals;
    if (orderTotals && USE_TRANSACTION_SUMMARY) {
      return (
        <div className="col">
          <CareResolutionNotification order={order} />
          <TransactionSummary
            order={order}
            totals={order.totals[order.totals.length - 1]}
            orderTransactions={order.transactions}
            suborders={order.suborders}
            canChargeOrder={canChargeOrder}
            setShowChargeOrder={setShowChargeOrder}
          />
        </div>
      );
    }

    if (orderTotals) {
      return (
        <div className="col">
          <CareResolutionNotification order={order} />
          <PaymentSummary order={order} summary={order.paymentSummary} canChargeOrder={canChargeOrder} setShowChargeOrder={setShowChargeOrder} />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <Recipient
            order={order}
            suborders={subordersCanUpdate}
            recipient={subordersCanUpdate.length ? subordersCanUpdate[0].recipient : suborder.recipient}
            setOrder={setOrder}
            edit={false}
            newOrder={false}
            setRecipientFields={() => {}}
            canUpdate={roleChecks.canUpdateRecipients(userGroups) && subordersCanUpdate.length > 0}
          />
          <Customer
            order={order}
            suborder={suborder}
            customer={customer}
            setOrder={setOrder}
            edit={false}
            newOrder={false}
            setCustomerFields={() => {}}
            canUpdate={roleChecks.canUpdateCustomers(userGroups)}
          />
        </div>
        <div className="col">
          <Message order={order} suborders={order.suborders} canUpdate={roleChecks.canUpdateOrderMessages(userGroups)} />
          {order.subscription && (
            <div>
              <ViewSubscription order={order} queryCache={queryCache} />
            </div>
          )}
        </div>
        {getPaymentSummaryComponent()}
      </div>
      <div className="col">
        {(order.suborders || []).map((sb) => (
          <Suborder
            order={order}
            suborder={sb}
            key={sb.suborderId}
            setOrder={setOrder}
            queryCache={queryCache}
            adjustments={orderAdjustments[sb.suborderId] || []}
            setShowAuditLog={setShowAuditLog}
            setDefaultAuditLogSelect={setDefaultAuditLogSelect}
          />
        ))}
        {roleChecks.canUpdateSuborders(userGroups) && (
          <div
            className="card card-inactive"
            style={{ cursor: 'pointer' }}
            onMouseOver={(e) => (e.currentTarget.className = 'card')}
            onMouseOut={(e) => (e.currentTarget.className = 'card card-inactive')}
            role="button"
            onClick={addNewSuborder}
          >
            <div className="card-body text-center">
              <PlusCircle size="2em" /> <span className="h4">Add a new suborder</span>
            </div>
          </div>
        )}
        <Dialog
          isShown={showAddSuborder}
          title="Add New Suborder"
          onCloseComplete={() => {
            setShowAddSuborder(false);
          }}
          confirmLabel="Confirm"
          onConfirm={handleAddSuborder}
        >
          <div>
            <div className="mb-4">
              Choose a product for the new suborder:
              <Select
                id="selectSuborderItem"
                onChange={(event) => setNewSuborder(event.value)}
                options={productOptions}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </div>
            <div className="mb-4">
              Select reason for adding new suborder:
              <Select
                id="selectAddSuborderReason"
                onChange={(event) => setAddSuborderReason(event)}
                options={addSuborderReasons}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </div>
            <div>
              <TextInput
                label="Provide any additional details for the reason:"
                name="AddSuborderReasonDetails"
                value={addSuborderDetails}
                onChange={(event) => {
                  setAddSuborderDetails(event.target.value);
                }}
              />
            </div>
            {newSuborder !== null && (
              <div className="mb-4">
                <Checkbox
                  label={`Will the customer be charged for this addition?${newSuborder ? ` ($${newSuborder.price})` : ''}`}
                  checked={newSuborderIsCharged}
                  onChange={(e) => setNewSuborderIsCharged(e.target.checked)}
                />
              </div>
            )}
          </div>
        </Dialog>
      </div>
    </>
  );
};

OrderDetail.propTypes = {
  order: PropTypes.object.isRequired,
  setOrder: PropTypes.func.isRequired,
  queryCache: PropTypes.object.isRequired,
  setShowAuditLog: PropTypes.func.isRequired,
  setDefaultAuditLogSelect: PropTypes.func,
};

export default OrderDetail;
