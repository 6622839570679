import { getSingleInventory } from '../../../../../services/AggregateService';
import formatDateString from '../../common/formatDateString';
import formatSpoilDate from '../../common/formatSpoilDate';
import getNoImageFoundImageUrl from './getNoImageFoundImageUrl';

const getSelectedInventoryRecordData = async (lotId, productFeed) => {
  try {
    const res = await getSingleInventory(lotId);
    const productFromFeed = productFeed[res.product_id.productSKU];
    const singleView = {
      adjustmentsSummary: res.summary,
      adjustments: res.adjustments,
      changelogs: res.delivery_memo,
      imageUrl: productFromFeed?.imageUrl || getNoImageFoundImageUrl(),
      externalLotId: res.netsuite_lot_id,
      internalLotId: res.delivery_id,
      productName: res.product_id.name,
      productSku: res.product_id.productSKU,
      status: res.shipment_status,
      fcId: res.distributionpoint_id,
      classification: res.product_id.classification,
      productFromResponse: res.product_id,
      details: [
        {
          order: 1,
          column: 'Netsuite Lot ID',
          value: res.netsuite_lot_id,
        },
        {
          order: 2,
          column: 'ADMIN Lot ID',
          value: res.delivery_id,
        },
        {
          order: 5,
          column: 'Vendor',
          value: res.vendor,
        },
        {
          order: 8,
          column: 'Arrival Date',
          value: formatDateString(res.arrival_date),
        },
        {
          order: 9,
          column: 'Start Date',
          value: formatDateString(res.start_date),
        },
        {
          order: 10,
          column: 'Spoil Date',
          value: formatSpoilDate(res.spoil_date),
        },
        {
          order: 11,
          column: 'Available for Sale',
          value: res.stock,
        },
        {
          order: 12,
          column: 'Count On Hand',
          value: res.count_on_hand,
        },
        {
          order: 3,
          column: 'Fulfillment Center',
          value: res.fulfillmentcenter_name,
        },
        {
          order: 4,
          column: 'Product',
          value: res.product_id.name,
        },
        {
          order: 6,
          column: 'Last Netsuite Transaction',
          value: res.purchase_order,
        },
        {
          order: 7,
          column: 'Purchase Stock',
          value: res.stock_initial,
        },
        {
          order: 13,
          column: 'Adjustments Made',
          value: res.adjustments.length,
        },
      ],
    };

    return singleView;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Had error while trying to get single view', { error });

    return null;
  }
};

export default getSelectedInventoryRecordData;
