/* eslint-disable no-console */
/* eslint-disable max-len */
import axios from 'axios';
import { DateTime } from 'luxon';

import { AGGREGATE_URL, AGGREGATE_SITE_KEY } from '../config';

/**
 * Get delivery windows
 *
 * @param {number} areaId Delivery area id
 * @param {DateTime} date Start date
 * @returns {success: boolean, deliveryWindows: []} Returns success and an array of delivery windows, or empty array if none
 */
export const getDeliveryWindows = async (areaId, date) => {
  try {
    const { data } = await axios.get(`${AGGREGATE_URL}/api/schedules/area/${areaId}/date/${date}/dash?site_key=${AGGREGATE_SITE_KEY}`);
    return {
      success: true,
      deliveryWindows: data,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};
/**
 * Get areas
 *
 * @param {object} coordinates latitude and longitude values
 * @returns {success: boolean, areas: []} Returns success and an array of areas, or empty array if none
 */
export const getAreas = async (coordinates, zipCode) => {
  const { lat, lng } = coordinates;

  try {
    const { data } = await axios.get(
      `${AGGREGATE_URL}/api/storefront/areas-with-zip?site_key=${AGGREGATE_SITE_KEY}&lat=${lat}&lng=${lng}&zip=${zipCode}`,
    );
    return {
      success: true,
      areas: data,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};

export const getDeliveryAreasByFc = async (fcId) => {
  try {
    const { data } = await axios.get(`${AGGREGATE_URL}/api/fcs/${fcId || 1}/deliveryareas?site_key=${AGGREGATE_SITE_KEY}`);
    return {
      success: true,
      areas: data,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};

export const getScheduleByDeliveryId = async (deliveryId) => {
  try {
    const { data } = await axios.get(`${AGGREGATE_URL}/api/areas/${deliveryId || 1}/schedules?site_key=${AGGREGATE_SITE_KEY}&onlyDayOfWeek=true`);
    return {
      success: true,
      schedules: data,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};

export const getDeliveryWindowsByDate = async (deliveryDate) => {
  try {
    const formattedDate = deliveryDate
      ? new Date(deliveryDate).toISOString().split('T')[0]
      : new Date().toISOString().split('T')[0];

    const data = await axios.get(
      `${AGGREGATE_URL}/api/delivery-windows/${formattedDate}?site_key=${AGGREGATE_SITE_KEY}`,
    );
    return {
      success: true,
      windows: data.data || [],
    };
  } catch (error) {
    console.error('Error fetching delivery windows:', error);

    return {
      success: false,
      error: error.message || 'Failed to fetch delivery windows',
    };
  }
};

export const getGeoJson = async (deliveryAreaId) => {
  try {
    const { data } = await axios.get(
      `${AGGREGATE_URL}/api/deliveryareas/${deliveryAreaId}/geojson`,
    );
    return {
      success: true,
      geojson: data[0].geojson,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};

/**
 * Get available inventory by FC and SKUs
 *
 * @param {array} fcs An array of FCs
 * @param {array} skus An array of SKUs
 * @returns {success: boolean, stock: object} Returns success and an object of availble stock by sku, fc, and date
 */
export const getInventory = async (fcs, skus, firstDate = DateTime.now().toISODate(), numDays = 1, considerCareBuffer = true) => {
  const postData = {
    fcs,
    skus,
  };

  try {
    const { data } = await axios.post(
      `${AGGREGATE_URL}/api/storefront/stock?first=${firstDate}&days=${numDays}&site_key=${AGGREGATE_SITE_KEY}&consider_care_buffer=${considerCareBuffer}`,
      postData,
    );
    return {
      success: true,
      stock: data,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};

/**
 * Get delivery schedules
 * @param {Object} fc - consists of fcId and timezone
 * @param {DateTime} date Start date
 * @returns {success: boolean, schedules: []} Returns success and an array of objects, or empty array if none
 */
export const fetchFCSchedule = async (date, fcID) => {
  try {
    const { data } = await axios.get(`${AGGREGATE_URL}/api/distributionpoints/${fcID}/schedules/date/${date}?site_key=${AGGREGATE_SITE_KEY}`);
    return {
      success: true,
      schedules: data,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};

/**
 *
 * @param {coords: object} recipient object with keys lat, lng
 * @param {array} lineitems An array of line items
 * @param {date: string} delivery
 */
export const getDeliveryAreaOptions = async (recipient, lineitems, delivery, considerCareBuffer = true) => {
  const postData = { recipient, lineitems, delivery };

  try {
    const { data } = await axios.post(
      `${AGGREGATE_URL}/api/storefront/options-with-zip?site_key=${AGGREGATE_SITE_KEY}&consider_care_buffer=${considerCareBuffer}`,
      postData,
    );
    return {
      success: true,
      data,
    };
  } catch (e) {
    console.error('Delivery area options response failure in DASH', e);
    return {
      success: false,
    };
  }
};

export const getInventoryForInventoryTable = async (fcId, date) => {
  try {
    if (!fcId || !date) throw new Error('No valid input');

    const response = await axios.get(
      `${AGGREGATE_URL}/api/inventory/shipment/deliveries-for-date?site_key=${AGGREGATE_SITE_KEY}&distributionPoint_id=${fcId}&date=${date}`,
    );

    return response.data;
  } catch (e) {
    console.error('Delivery area options response failure in DASH', e);
    return null;
  }
};

export const getSingleInventory = async (shipmentId) => {
  try {
    if (!shipmentId) throw new Error('No valid input');

    const response = await axios.get(`${AGGREGATE_URL}/api/inventory/shipment/${shipmentId}?site_key=${AGGREGATE_SITE_KEY}`);

    return response.data;
  } catch (e) {
    console.error('Inventory response failure in DASH', e);
    return null;
  }
};

export const createAdjustment = async (postData) => {
  try {
    const url = `${AGGREGATE_URL}/api/adjustments?site_key=${AGGREGATE_SITE_KEY}`;
    const response = await axios.post(url, postData);

    return { data: response.data, success: true };
  } catch (error) {
    console.error('Could not access createAdjustment API', error);
    return { success: false, error };
  }
};

export const updateAdjustment = async (postData, adjustmentId) => {
  try {
    const url = `${AGGREGATE_URL}/api/adjustments/${adjustmentId}?site_key=${AGGREGATE_SITE_KEY}`;
    const response = await axios.put(url, postData);

    return { data: response.data, success: true };
  } catch (error) {
    console.error('Could not access updateAdjustment API', error);
    return { success: false, error };
  }
};
