/* eslint-disable no-console */
import React, { useEffect, useState, useContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { Checkbox, Dialog, Switch, Spinner } from 'evergreen-ui';
import { DateTime } from 'luxon';
import { getDeliveryWindows, getAreas, getDeliveryAreaOptions } from '../../services/AggregateService';
import { getSuborder, updateSuborderDelivery } from '../../services/OrderManagementService';
import { LookupContext } from '../../context/lookupContext';
import { AuthContext } from '../../context/authContext';
import Progress from '../common/Progress';
import TextInput from '../common/TextInput';
import { roleChecks } from '../../utilities/Role';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css';
// eslint-disable-next-line import/no-cycle
import { deallocateStrategies } from './ViewOrder';
import DeliveryOptionSelectionComponent from './helpers/DeliveryOptionSelectionComponent';
import BestOptionDatePicker from './helpers/BestOptionDatePicker';

const UpdateDelivery = ({
  suborders,
  fcId,
  deliveryAreas,
  showChangeDelivery,
  setShowChangeDelivery,
  onComplete,
  coords,
  zipCode,
  newOrder,
  setDeliveryFields,
  useDialog = true,
  redeliveryArgs,
  setDisable = () => {},
}) => {
  const [areasFromCoordsApi, setAreasFromCoordsApi] = useState(null);
  const { deliveryAreas: deliveryAreaLookup, fulfillmentCenters, productFeed } = useContext(LookupContext);
  const { userGroups } = useContext(AuthContext);
  const [fcOptions, setFcOptions] = useState(null);
  const [fc, setFc] = useState(null);
  const [deliveryArea, setDeliveryArea] = useState(null);
  const [areaOptions, setAreaOptions] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(redeliveryArgs ? redeliveryArgs.deliveryDate : null);
  const [windowOptions, setWindowOptions] = useState([]);
  const [deliveryWindow, setDeliveryWindow] = useState(null);

  const [totalToSave, setTotalToSave] = useState(0);
  const [saveFailures, setSaveFailures] = useState([]);
  const [totalSaved, totalSavedDispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'increment':
        return state + 1;
      case 'reset':
        return 0;
      default:
        break;
    }
  }, 0);

  const [addNewDeliveryWindow, setAddNewDeliveryWindow] = useState(false);
  const [overrideDeliveryTime, setOverrideDeliveryTime] = useState(null);
  const [overrideDeliveryDuration, setOverrideDeliveryDuration] = useState('');

  const [deallocateStrategy, setDeallocateStrategy] = useState('delete');
  const [usingBestOptionsToggle, setBestOptionToggle] = useState(!newOrder);
  const [bestOptionDate, setBestOptionDate] = useState(redeliveryArgs ? redeliveryArgs.deliveryDate : null);
  const [mapOfSuborderIdsToBestOptionsArray, setMapOfSuborderIdsToBestOptionsArray] = useState({});
  const [mapOfSuborderIdsToErrorMessagesForBestOptions, setMapOfSuborderIdsToErrorMessagesForBestOptions] = useState({});
  const [selectedBestOption, setSelectedBestOption] = useState(null);
  const [saveSuccessIds, setSaveSuccessIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bestOptionDeliveryFields, setBestOptionDeliveryFields] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [buttonText, setButtonText] = useState('Cancel');

  const isBulk = suborders && suborders.length > 1;
  const hasCoordsAndZip = (suborders?.length === 1 && coords && zipCode) || ((newOrder || redeliveryArgs) && coords && zipCode);

  const disableSave =
    isUpdating ||
    saveFailures.length ||
    (addNewDeliveryWindow
      ? !(deliveryArea && fc && overrideDeliveryTime && overrideDeliveryDuration)
      : usingBestOptionsToggle
        ? isLoading || !bestOptionDate || !selectedBestOption
        : !(deliveryWindow && deliveryArea && fc && deliveryDate));

  // Checks if suborders have the same current values for area, date, and window
  const subordersProps = useMemo(() => {
    if (newOrder) {
      return {
        deliveryArea: null,
        deliveryDate: null,
        window: null,
      };
    }
    if (redeliveryArgs) {
      return {
        deliveryArea: redeliveryArgs.deliveryArea,
        deliveryDate: redeliveryArgs.deliveryDate,
        window: redeliveryArgs.window,
      };
    }
    return {
      deliveryArea: new Set(suborders.map((s) => s.deliveryArea)).size === 1 ? suborders[0].deliveryArea : null,
      deliveryDate:
        new Set(suborders.filter((s) => s.startTime != null).map((s) => s.startTime.split('T')[0])).size === 1
          ? suborders[0].startTime.split('T')[0]
          : null,
      window: new Set(suborders.map((s) => s.window)).size === 1 ? suborders[0].window : null,
    };
  }, [suborders, newOrder]);

  // Form Submit
  const handleUpdateDelivery = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsUpdating(true);
    setButtonText('Close');

    const fields = {};
    const options = {
      useCareBuffer: true,
    };

    if (!usingBestOptionsToggle) {
      // update FC
      const fcInfo = fulfillmentCenters[fc.value];
      if (Number(fc.value) !== fcId) {
        fields.fc = {
          fcId: Number(fc.value),
          timezone: fcInfo.timezone,
          company: fcInfo.company,
          address1: fcInfo.address1,
          address2: fcInfo.address2,
          city: fcInfo.city,
          state: fcInfo.state,
          zipCode: fcInfo.zipcode,
          phone: fcInfo.phone,
        };
      }
      // populate fields using regular conventions
      // update date and window, compute proper fields if using window override
      const { timezone } = fcInfo;

      if (addNewDeliveryWindow) {
        const startDate = DateTime.fromISO(deliveryDate, { zone: timezone });
        const startDateTime = startDate.set({
          hour: DateTime.fromJSDate(overrideDeliveryTime).hour,
          minute: DateTime.fromJSDate(overrideDeliveryTime).minute,
        });

        const fulfilledBy = startDateTime.minus({ minutes: deliveryAreas[deliveryArea.value].travelTime });

        fields.startTime = DateTime.fromISO(startDateTime).toSQL();
        fields.windowDuration = Number(overrideDeliveryDuration);
        fields.orderFulfilledBy = DateTime.fromISO(fulfilledBy).toSQL();
      } else if (deliveryDate !== subordersProps.deliveryDate || subordersProps.window !== deliveryWindow.value.duration) {
        fields.startTime = DateTime.fromISO(deliveryWindow.value.startTime).toSQL();
        fields.windowDuration = deliveryWindow.value.duration;
        fields.orderFulfilledBy = deliveryWindow.value.window.order_fulfilled_by;
      }

      // update area
      if (deliveryArea.value !== subordersProps.deliveryArea || redeliveryArgs) {
        fields.areaId = deliveryArea.value;
        fields.transport = deliveryAreas[deliveryArea.value].transport;
        fields.transportMode = deliveryAreas[deliveryArea.value].transportMode;
        fields.transportVendorDeliveryName = deliveryAreas[deliveryArea.value].transportVendor;
      }
    }

    options.deallocateStrategy = deallocateStrategy;

    if (newOrder || redeliveryArgs) {
      try {
        setDeliveryFields({
          areaId: fields.areaId,
          startTime: fields.startTime,
          orderFulfilledBy: fields.orderFulfilledBy,
          windowDuration: fields.windowDuration,
          transport: deliveryAreas[fields.areaId].transport,
          transportMode: deliveryAreas[fields.areaId].transportMode,
          transportVendorDeliveryName: deliveryAreas[fields.areaId].transportVendor,
          fc: { ...fields.fc },
          transportVendorDelivery: {
            name: deliveryAreas[fields.areaId].transportVendor,
          },
        });
        setShowChangeDelivery(false);
        setDisable(false);
      } catch (e) {
        console.error('Reaching error in setting fields', e);
        setDisable(true);
      }
    } else {
      setSaveFailures([]);
      setTotalToSave(suborders.length);
      setSaveSuccessIds([]);

      const postPromiseFunctionality = (syncedVariableHolder) => {
        totalSavedDispatch({ type: 'increment' });
        syncedVariableHolder.countdown--;
        if (!syncedVariableHolder.countdown) {
          if (syncedVariableHolder.failures.length) {
            setSaveFailures(syncedVariableHolder.failures);
          }
          setTimeout(() => {
            setTotalToSave(0);
            totalSavedDispatch({ type: 'reset' });
            if (!syncedVariableHolder.failures.length) {
              onComplete();
            }
          }, 2000);
        }
      };

      new Promise((resolve, reject) => {
        const syncedVariableHolder = {
          countDown: suborders.length,
          failures: [],
        };
        /* eslint-disable no-loop-func */
        for (const suborder of suborders) {
          const { suborderId } = suborder;

          if (usingBestOptionsToggle && mapOfSuborderIdsToErrorMessagesForBestOptions[suborderId] !== undefined) {
            // there was an error with this; print notification
            syncedVariableHolder.failures.push({ id: suborderId, error: mapOfSuborderIdsToErrorMessagesForBestOptions[suborderId], success: false });
            postPromiseFunctionality(syncedVariableHolder);
          } else {
            // able to update the suborder delivery
            const suborderFields = usingBestOptionsToggle ? bestOptionDeliveryFields : fields;

            new Promise((resolve, reject) => updateSuborderDelivery(suborderId, suborderFields, options)
              .then((res) => {
                if (!res.success) {
                  syncedVariableHolder.failures.push({ id: suborder.suborderId, ...res, error: res.message });
                } else {
                  saveSuccessIds.push(suborderId);
                  setSaveSuccessIds(saveSuccessIds);
                }
              })
              .catch((err) => {
                console.error(err);
                syncedVariableHolder.failures.push({ id: suborder.suborderId, error: err.message, success: false });
              })
              .finally(() => postPromiseFunctionality(syncedVariableHolder)));
          }
        }
        /* eslint-enable no-loop-func */
      });
    }
    setIsUpdating(false);
  };
  // for redeliveryArgs
  useEffect(() => {
    if (redeliveryArgs && !usingBestOptionsToggle) {
      if (disableSave) {
        setDisable(true);
      }
      if (!disableSave) {
        handleUpdateDelivery();
      }
    }
  }, [
    deliveryWindow,
    deliveryArea,
    fc,
    deliveryDate,
    usingBestOptionsToggle,
    isLoading,
    bestOptionDate,
    overrideDeliveryTime,
    overrideDeliveryDuration,
  ]);

  useEffect(() => {
    if (redeliveryArgs) {
      if (redeliveryArgs.deliveryDate !== bestOptionDate) {
        setBestOptionDate(redeliveryArgs.deliveryDate);
      }
    }

    if (!usingBestOptionsToggle) {
      setSaveFailures([]);
    }

    if (usingBestOptionsToggle && (redeliveryArgs || bestOptionDate)) {
      retrieveBestOptionsFromDeliveryDate(bestOptionDate || undefined);
    }
  }, [usingBestOptionsToggle, bestOptionDate]);

  useEffect(() => {
    if (redeliveryArgs && redeliveryArgs.deliveryDate) {
      setDeliveryDate(redeliveryArgs.deliveryDate);
    }
    setFc(null);
    setDeliveryArea(null);
    setDeliveryWindow(null);

    if (usingBestOptionsToggle && redeliveryArgs && redeliveryArgs.deliveryDate) {
      retrieveBestOptionsFromDeliveryDate(redeliveryArgs.deliveryDate);
    }
  }, [redeliveryArgs?.deliveryDate]);

  useEffect(() => {
    if (redeliveryArgs && usingBestOptionsToggle) {
      retrieveBestOptionsFromDeliveryDate(redeliveryArgs.deliveryDate);
    }
  }, [redeliveryArgs?.lineItems]);

  // Use recipient coordinates and zip to fetch delivery areas, if available
  useEffect(() => {
    const getAreasfromCoords = async (coords, zipCode) => {
      const response = await getAreas(coords, zipCode); // API Call

      if (!response.success) {
        setAreasFromCoordsApi(null);
      } else {
        setAreasFromCoordsApi(response.areas);
      }
    };

    if (hasCoordsAndZip) {
      getAreasfromCoords(coords, zipCode);
    }
  }, [coords, zipCode, hasCoordsAndZip]);

  // set FC options
  useEffect(() => {
    let allFcOptions = [];
    const fcAreaCoordsOptions = [];
    const otherFcOptions = [];

    const createFcOption = (fc) => {
      return { value: fulfillmentCenters[fc].id.toString(), label: fulfillmentCenters[fc].name };
    };

    // Group FCs
    for (const fcOption in fulfillmentCenters) {
      const option = createFcOption(fcOption);
      const updateSingleDeliveryPermissionCheck =
        hasCoordsAndZip && (!newOrder || !redeliveryArgs) && !roleChecks.canUpdateDeliveryInUpdateDeliveryComponent(userGroups);
      if (areasFromCoordsApi) {
        if (areasFromCoordsApi.find((a) => a.fc.id === fulfillmentCenters[fcOption].id)) {
          fcAreaCoordsOptions.push(option);
        } else {
          if (updateSingleDeliveryPermissionCheck) {
            option.isDisabled = true;
          }
          otherFcOptions.push(option);
        }
      } else {
        if (updateSingleDeliveryPermissionCheck) {
          option.isDisabled = true;
        }
        otherFcOptions.push(option);
      }
    }

    allFcOptions = [
      {
        label: 'FCs matching recipient area coordinates',
        options: fcAreaCoordsOptions,
      },
      {
        label: 'Other FCs',
        options: otherFcOptions,
      },
    ];

    setFcOptions(allFcOptions);

    let currentFc = null;

    // set initially selected FC
    if (!redeliveryArgs) {
      for (const fcCategory in allFcOptions) {
        currentFc = allFcOptions[fcCategory].options.find((fc) => Number(fc.value) === fcId);
        if (currentFc) {
          break;
        }
      }
      setFc(currentFc);
    }
  }, [fulfillmentCenters, areasFromCoordsApi, fcId, userGroups, hasCoordsAndZip, newOrder, redeliveryArgs]);

  const getDeliveryAreaPrioritySorter = (area1, area2) => area1.priority - area2.priority;

  const getDeliveryAreasOrderedByPriority = () => {
    const arr = [];
    for (const area in deliveryAreas) {
      arr.push(deliveryAreas[area]);
    }
    arr.sort(getDeliveryAreaPrioritySorter);

    return arr;
  };

  const getAllowedTransportModes = (lineItems) => {
    const arrayOfLineItemTransportModeArrays = [];
    const skus = lineItems.map((li) => li.sku);

    for (const sku of skus) {
      if (productFeed[sku]) {
        const product = productFeed[sku];
        const { allowedTransportModes } = product;
        arrayOfLineItemTransportModeArrays.push(allowedTransportModes);
      }
    }

    return arrayOfLineItemTransportModeArrays.length ? arrayOfLineItemTransportModeArrays.reduce((a, b) => a.filter((c) => b.includes(c))) : []; // this gets the intersection
  };

  const getIdsOfAreasFromCoords = () => {
    if (areasFromCoordsApi !== null) {
      return areasFromCoordsApi.map((area) => area.id);
    }
    return null;
  };

  // Set delivery area options
  useEffect(() => {
    // Filter areas by current FC and Coordinates or just FC
    let allAreaOptions = [];
    const allowedTransportModeAreas = [];
    const otherAreaOptions = [];

    let areaFc;

    if (fc) {
      areaFc = Number(fc.value);
    } else {
      areaFc = fcId;
    }

    const createAreaOption = (area) => {
      if (area && area.isActive) {
        return {
          value: area.id,
          label: area.description ? area.description : area.id,
        };
      }
    };

    const lineItemsForDelivery = suborders
      ? suborders
        .map((suborder) => suborder.lineItems)
        .filter((lineItem) => !lineItem.isDeleted)
        .flat()
      : [];
    const allowedTransportModes = getAllowedTransportModes(lineItemsForDelivery);

    const deliveryAreasOrderedByPriority = getDeliveryAreasOrderedByPriority();

    const idsOfAreasFromCoords = getIdsOfAreasFromCoords();

    for (const area of deliveryAreasOrderedByPriority) {
      if (area.fcId === areaFc) {
        const option = createAreaOption(area);

        if (option) {
          const allowedTransportModeCheck = allowedTransportModes.includes(area.transportMode);
          const isAppropriateWithRegardsToDistanceCheck = idsOfAreasFromCoords === null || idsOfAreasFromCoords.includes(area.id);
          if (allowedTransportModeCheck && isAppropriateWithRegardsToDistanceCheck) {
            allowedTransportModeAreas.push(option);
          } else {
            otherAreaOptions.push(option);
            if (!newOrder && !roleChecks.canSelectOtherDeliveryAreas(userGroups)) {
              option.isDisabled = true;
            }
          }
        }
      }
    }

    allAreaOptions = [
      {
        label: 'Available Delivery Areas',
        options: allowedTransportModeAreas,
      },
      {
        label: 'Other Areas',
        options: otherAreaOptions,
      },
    ];

    setAreaOptions(allAreaOptions);

    // Set selected area
    if (!redeliveryArgs) {
      if (subordersProps.deliveryArea) {
        let currentArea = null;

        for (const areaCategory of allAreaOptions) {
          currentArea = areaCategory.options.find((area) => area.value === Number(subordersProps.deliveryArea));
          if (currentArea) {
            break;
          }
        }

        if (currentArea) {
          setDeliveryArea(currentArea);
        } else {
          setDeliveryArea(null);
        }
      } else {
        setDeliveryArea(null);
      }
    }

    if (subordersProps.deliveryDate && deliveryDate === null) {
      setDeliveryDate(subordersProps.deliveryDate);
    }
  }, [fc, areasFromCoordsApi, fcId, deliveryAreas, subordersProps, userGroups, hasCoordsAndZip, newOrder]);

  const computeWindow = (window) => {
    const startDateTime = window.starts_at.includes('T')
      ? DateTime.fromISO(window.starts_at, { setZone: true })
      : DateTime.fromSQL(window.starts_at, { setZone: true });
    const endDateTime = startDateTime.plus({ hours: window.duration });

    return {
      value: { window, startTime: window.starts_at, duration: window.duration },
      label: `${startDateTime.toFormat('hh:mm a')} - ${endDateTime.toFormat('hh:mm a')}`,
    };
  };

  // Set window options
  useEffect(() => {
    const getWindows = async () => {
      let area = null;
      if (deliveryArea) {
        area = deliveryArea.value;
      } else if (subordersProps.deliveryArea) {
        area = subordersProps.deliveryArea;
      }

      if (area && deliveryDate) {
        const result = await getDeliveryWindows(area, deliveryDate);

        if (result.success) {
          const windows = result.deliveryWindows;

          let options = [];
          const availableWindows = [];
          const windowsPastCutoff = [];
          const windowsPastStart = [];

          windows.forEach((option) => {
            const window = computeWindow(option);
            if (option.past_cutoff === false && option.past_start === false) {
              availableWindows.push(window);
            } else if (option.past_cutoff === true && option.past_start === false) {
              windowsPastCutoff.push(window);
            } else {
              windowsPastStart.push(window);
            }
          });

          options = [
            {
              label: 'Available Windows',
              options: availableWindows,
            },
            {
              label: 'Windows Past Cutoff',
              options: windowsPastCutoff,
            },
            {
              label: 'Windows Past Start',
              options: windowsPastStart,
            },
          ];

          setWindowOptions(options);
        }
      }
    };

    getWindows();
  }, [deliveryDate, deliveryArea, subordersProps]);

  // set selected window
  useEffect(() => {
    if (!!windowOptions.length && !redeliveryArgs) {
      if (subordersProps.window) {
        const availableOptions = windowOptions.find((el) => el.label === 'Available Windows');

        const selectedWindow = availableOptions.options.find((window) => {
          return window.label === subordersProps.window;
        });

        if (selectedWindow) {
          setDeliveryWindow(selectedWindow);
        } else {
          setDeliveryWindow(null);
        }
      } else {
        setDeliveryWindow(null);
      }
    }
  }, [windowOptions, subordersProps]);

  const getCoordsForSuborder = async (suborder) => {
    const omsSuborderResponse = await getSuborder(suborder.suborderId);

    if (!omsSuborderResponse.success) {
      throw Error(`No OMS suborder found on lookup: ${suborder.suborderId}`);
    }

    const omsSuborder = omsSuborderResponse.suborder;

    return { coords: omsSuborder.recipient.coords, zipCode: omsSuborder.recipient.zipCode };
  };

  const getNonDeletedLineItems = (lineItemArray) => lineItemArray.filter((li) => !li.isDeleted);

  const handleBestOptionDateChange = async (datestr) => {
    setSaveFailures([]);
    setSelectedBestOption(null);
    setBestOptionDate(datestr);
  };

  const convertOptionToOmsFriendlyOption = (option) => {
    const omsFriendlyOption = {
      deliveryAreaName: deliveryAreaLookup[option.deliveryArea.id] ? deliveryAreaLookup[option.deliveryArea.id].description : '',
      windowDescription: computeWindow(option.window).label,
      startTime: option.window.starts_at,
      windowDuration: option.window.duration,
      orderFulfilledBy: option.window.order_prepped_by,
      areaId: option.deliveryArea.id,
      transport: option.deliveryArea.delivery_type,
      transportMode: option.deliveryArea.transportMode,
      fc: {
        ...option.fc,
        fcId: option.fc.id,
        zipCode: option.fc.zipcode,
      },
      transportVendorDeliveryName: deliveryAreas[option.deliveryArea.id].transportVendor,
      transportVendorDelivery: {
        name: deliveryAreas[option.deliveryArea.id].transportVendor,
      },
    };
    delete omsFriendlyOption.fc.id;
    delete omsFriendlyOption.fc.zipcode;

    return omsFriendlyOption;
  };

  const retrieveBestOptionsFromDeliveryDate = async (inputDelivDate) => {
    setMapOfSuborderIdsToBestOptionsArray({});
    setIsLoading(true);

    const dateToUse = inputDelivDate || deliveryDate;

    const newMapOfSuborderIdsToBestOptionsArray = {};
    const newMapOfSuborderIdsToErrorMessagesForBestOptions = {};

    let atLeastOneExists = false; // redelivery specific logic

    for (const suborder of suborders) {
      const bestOptions = [];

      try {
        const suborderCoordsAndZip = hasCoordsAndZip ? { coords, zipCode } : await getCoordsForSuborder(suborder);
        const recipient = {
          coords: suborderCoordsAndZip.coords,
          zip: suborderCoordsAndZip.zipCode,
        };

        const lineitems = redeliveryArgs ? redeliveryArgs.lineItems : getNonDeletedLineItems(suborder.lineItems);

        const allowedTransportModes = getAllowedTransportModes(lineitems);

        const delivery = {
          date: dateToUse,
        };

        const deliveryAreaOptionsResponse = await getDeliveryAreaOptions(recipient, lineitems, delivery);

        if (!deliveryAreaOptionsResponse.success) {
          console.error({ deliveryAreaOptionsResponse });
          throw Error('No delivery area options response available.');
        }

        const deliveryAreaOptions = deliveryAreaOptionsResponse.data;

        const { ranking, options } = deliveryAreaOptions;

        if (!ranking || !ranking.length) {
          console.warn({ deliveryAreaOptions });
          throw Error('No delivery area options available.');
        }

        for (const deliveryAreaRanking of ranking) {
          const currentFc = deliveryAreaRanking.fc;
          const optionForRanking = options[currentFc.id][deliveryAreaRanking.id][dateToUse];

          const { transportMode } = deliveryAreas[deliveryAreaRanking.id];

          if (optionForRanking && optionForRanking.windows && optionForRanking.windows.length && allowedTransportModes.includes(transportMode)) {
            const currentOption = {
              deliveryArea: deliveryAreaRanking,
              window: optionForRanking.windows[0],
              fc: currentFc,
            };
            bestOptions.push(convertOptionToOmsFriendlyOption(currentOption));
          }
        }

        if (bestOptions.length === 0) {
          throw Error('No delivery area options available.');
        }

        atLeastOneExists = true;

        newMapOfSuborderIdsToBestOptionsArray[suborder.suborderId] = bestOptions;
      } catch (badUpdateError) {
        console.warn("Won't be updating this suborder due to reason", suborder, badUpdateError);

        newMapOfSuborderIdsToErrorMessagesForBestOptions[suborder.suborderId] = badUpdateError.message;
      }
    }

    setDisable(!atLeastOneExists);

    setMapOfSuborderIdsToBestOptionsArray(newMapOfSuborderIdsToBestOptionsArray);
    setMapOfSuborderIdsToErrorMessagesForBestOptions(newMapOfSuborderIdsToErrorMessagesForBestOptions);

    setSaveFailures(
      Object.keys(newMapOfSuborderIdsToErrorMessagesForBestOptions).map((key) => ({
        id: key,
        error: newMapOfSuborderIdsToErrorMessagesForBestOptions[key],
        success: false,
      })),
    );

    setIsLoading(false);
  };


  const handleDateChange = (date, datestr) => {
    setDeliveryDate(datestr);
  };

  const handleAreaChange = (areaId) => {
    let currentArea = null;
    setDeliveryWindow(null);

    for (const areaCategory of areaOptions) {
      currentArea = areaCategory.options.find((area) => area.value === areaId);
      if (currentArea) {
        setDeliveryArea(currentArea);
        break;
      }
    }
  };

  const handleFcChange = (fcId) => {
    setDeliveryArea(null);
    setDeliveryWindow(null);
    for (const fcCategory in fcOptions) {
      const currentFc = fcOptions[fcCategory].options.find((fc) => fc.value === fcId);
      if (currentFc) {
        setFc(currentFc);
        break;
      }
    }
  };

  const renderForm = () => (
    <>
      <div className={redeliveryArgs ? '' : 'card-body pl-0 pt-0'}>
        {!redeliveryArgs && (
          <div className="row">
            <div className="mb-3">
              {suborders ? <p>For suborders: {suborders.map((suborder) => suborder.suborderId).join(',')} </p> : <p>Create delivery: </p>}
            </div>
          </div>
        )}
        {!newOrder && (
          <div className="row">
            <div className="mb-3">
              <span style={{ verticalAlign: 'middle' }}>Best Option </span>
              <Switch
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                checked={usingBestOptionsToggle}
                onChange={(e) => setBestOptionToggle(e.target.checked)}
              />
            </div>
          </div>
        )}
        {usingBestOptionsToggle && (
          <>
            {!redeliveryArgs && <BestOptionDatePicker bestOptionDate={bestOptionDate} setBestOptionDate={handleBestOptionDateChange} />}
            {(redeliveryArgs || bestOptionDate) &&
              Object.keys(mapOfSuborderIdsToBestOptionsArray).length === 1 &&
              suborders.length &&
              mapOfSuborderIdsToBestOptionsArray[suborders[0].suborderId] !== undefined &&
              mapOfSuborderIdsToBestOptionsArray[suborders[0].suborderId]
                .sort((option1, option2) => getDeliveryAreaPrioritySorter(deliveryAreas[option1.areaId], deliveryAreas[option2.areaId]))
                .map((bestOption, index) => (
                  <div className={redeliveryArgs ? 'row' : 'mt-3'} key={`option${index}`}>
                    <DeliveryOptionSelectionComponent
                      fcName={bestOption.fc.name}
                      deliveryAreaName={bestOption.deliveryAreaName}
                      window={bestOption.windowDescription}
                      isSelected={selectedBestOption && selectedBestOption.deliveryAreaName === bestOption.deliveryAreaName}
                      setIsSelected={() => setSelectedBestOption(bestOption)}
                      onSelection={() => (redeliveryArgs ? setDeliveryFields(bestOption) : setBestOptionDeliveryFields(bestOption))}
                    />
                  </div>
                ))}
            {isLoading && <Spinner />}
          </>
        )}
        {!usingBestOptionsToggle && (
          <>
            <div className="row">
              <div className="mb-3">
                <Select
                  id="selectFC"
                  value={fc}
                  placeholder="Select Fulfillment Center..."
                  onChange={(option) => handleFcChange(option.value)}
                  options={fcOptions}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Select
                  id="selectDeliveryArea"
                  placeholder="Select Delivery Area..."
                  value={deliveryArea}
                  onChange={(option) => handleAreaChange(option.value)}
                  options={areaOptions}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                />
              </div>
            </div>
            {!redeliveryArgs && (
              <div className="row">
                <div className="mb-3">
                  <Flatpickr
                    className="form-control"
                    value={deliveryDate}
                    onChange={(date, datestr) => handleDateChange(date, datestr)}
                    options={{ minDate: DateTime.now().toISO() }}
                  />
                </div>
              </div>
            )}
            {!addNewDeliveryWindow && (
              <div className="row">
                <div className="mb-3">
                  <Select
                    id="selectDeliveryWindow"
                    value={deliveryWindow}
                    placeholder="Select Delivery Window..."
                    onChange={(option) => {
                      setDeliveryWindow(option);
                    }}
                    options={windowOptions}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </div>
            )}

            {roleChecks.canUpdateDeliveryInUpdateDeliveryComponent(userGroups) && (
              <div className="row">
                <div className="mb-3">
                  <Checkbox
                    label="Add a custom delivery window"
                    checked={addNewDeliveryWindow}
                    onChange={(e) => setAddNewDeliveryWindow(e.target.checked)}
                  />
                </div>
              </div>
            )}
            {addNewDeliveryWindow && (
              <>
                <div className="row">
                  <label>Window start time</label>
                  <div className="mb-3">
                    <Flatpickr
                      className="window start time override"
                      value={overrideDeliveryTime}
                      onChange={(date) => {
                        setOverrideDeliveryTime(date[0]);
                      }}
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'h:i K',
                        time_24hr: false,
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3">
                    <TextInput
                      label="Window duration in hours"
                      onChange={(e) => setOverrideDeliveryDuration(e.target.value)}
                      name="windowDurationOverride"
                      value={overrideDeliveryDuration}
                      validate={(val) => parseInt(val) && Number(val) > 0}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {!redeliveryArgs && fc && parseInt(fc.value) !== fcId && (
          <div className="row">
            <div className="mb-3">
              What should we do with the inventory?
              <Select
                id="selectDallocateType"
                defaultValue={deallocateStrategies.find((x) => x.value === deallocateStrategy)}
                onChange={(event) => setDeallocateStrategy(event.value)}
                options={deallocateStrategies}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-2 mx-4">
        {totalToSave > 0 && <Progress percentage={Math.ceil(totalSaved / totalToSave) * 100} />}

        {!!saveSuccessIds.length && (
          <div>
            <p className="text-success mt-4">Update Successes</p>
            <ul>
              {saveSuccessIds.map((successId) => (
                <li className="text-success" key={successId}>{`${successId} successfully updated`}</li>
              ))}
            </ul>
          </div>
        )}

        {totalToSave > 0 && totalToSave === totalSaved && !saveFailures.length && <p className="text-success">Done!</p>}

        {!!saveFailures.length && (
          <div>
            <p className={redeliveryArgs ? 'text-danger' : 'text-danger mt-4'}>Update Failures</p>
            <ol>
              {saveFailures.map((f) => (
                <li className="text-danger" key={f.id}>{`${f.id}: ${f.error}`}</li>
              ))}
            </ol>
          </div>
        )}
        <br />
      </div>
      {!redeliveryArgs && (
        <div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary mx-1" disabled={disableSave} onClick={handleUpdateDelivery}>
              Update
            </button>
            <button type="button" className="btn btn-secondary" onClick={() => setShowChangeDelivery(false)}>
              {buttonText}
            </button>
          </div>
        </div>
      )}
    </>
  );

  return useDialog ? (
    <Dialog
      title={isBulk ? 'Bulk Update' : 'Update Suborder Delivery'}
      isShown={showChangeDelivery}
      onCloseComplete={() => setShowChangeDelivery(false)}
      hasFooter={false}
      confirmLabel="Update"
    >
      {renderForm()}
    </Dialog>
  ) : (
    renderForm()
  );
};

UpdateDelivery.propTypes = {
  suborders: PropTypes.array,
  fcId: PropTypes.number,
  deliveryAreas: PropTypes.object.isRequired,
  showChangeDelivery: PropTypes.bool.isRequired,
  setShowChangeDelivery: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  coords: PropTypes.object,
  zipCode: PropTypes.string,
  newOrder: PropTypes.bool.isRequired,
  setDeliveryFields: PropTypes.func,
  useDialog: PropTypes.bool,
  redeliveryArgs: PropTypes.object,
  setDisable: PropTypes.func,
};

export default UpdateDelivery;
