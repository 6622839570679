import React, { useContext, useState } from 'react';
import { DateTime } from 'luxon';
import Footer from '../../common/wizards/Footer';
import { LookupContext } from '../../../context/lookupContext';
import { addNewSuborderToOrderForResolution, issueCredits, refundOrder } from '../../../services/OrderManagementService';
import Progress from '../../common/Progress';
import { getAdjustedPaymentTotal } from '../helpers/getAdjustedPaymentTotal';
import getIdealProductName from '../helpers/getIdealProductName';

const Summary = ({ order, suborder, state, closeModal, stepThreeState, ...props }) => {
  const { deliveryAreas: deliveryAreaLookup, productFeed } = useContext(LookupContext);
  const [hasProcessedFunctions, setHasProcessedFunctions] = useState(false);
  const [isProcessingFunctions, setIsProcessingFunctions] = useState(false);
  const [totalNumThatHasToBeProcessed, setTotalNumThatHasToBeProcessed] = useState(0);
  const [numThatHasBeenProcessed, setNumThatHasBeenProcessed] = useState(0);
  const [backendSuccesses, setBackendSuccesses] = useState([]);
  const [backendErrors, setBackendErrors] = useState([]);
  const [allowRectification, setAllowRectifiation] = useState(false);

  const issuingRefund = state.needsRefundOrCredits && stepThreeState.issuingRefund;

  const createCardForRefundOrCredits = ({ headerWord, dollarAmount, to }) => {
    return (
      <div className="row">
        <div className="card">
          <div className="p-3 card-body">
            <div className="row align-items-center">
              <h4>{headerWord}:</h4>
              <div>
                ${dollarAmount} to {to}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getBestTransactionIdentifierForRefund = () => {
    if (order.transactions) {
      const lastTransaction = order.transactions[order.transactions.length - 1];
      if (lastTransaction.brand && lastTransaction.panTruncation) {
        return `${lastTransaction.brand} ${lastTransaction.panTruncation}`;
      }
      return lastTransaction.paymentType;
    }
    return `${order.customer.firstName} ${order.customer.lastName}`;
  };

  const getCreditAmount = () => (state.creditAmountToCustomer > 0 ? state.creditAmountToCustomer : state.creditAmountToRecipient);
  const getCreditEmail = () => (state.creditAmountToCustomer > 0 ? state.creditCustomerEmail : state.creditRecipientEmail);

  const getLineItemDisplay = (lineItem) => `${lineItem.quantity}x ${getIdealProductName(productFeed, lineItem)}`;

  const getDelivery = () => state.redeliverySuborder.delivery;

  const getDeliveryAreaName = () => (getDelivery().deliveryAreaName
    ? getDelivery().deliveryAreaName
    : deliveryAreaLookup[getDelivery().areaId]
      ? deliveryAreaLookup[getDelivery().areaId].description
      : '');

  const getDeliveryStartDate = () => DateTime.fromSQL(getDelivery().startTime).toLocaleString(DateTime.DATE_HUGE);

  const printReason = (reason) => (reason.details ? `${reason.tag} - ${reason.details}` : reason.tag);

  const createReasonsFromStateVars = () => ({
    suborderReasons: state.suborderLevelReasons,
    lineItemReasons: state.lineItemsWithIssue.map((lineItem) => lineItem.userActionReasons ?? []).flat(),
  });

  const getSanitizedDelivery = () => {
    const copyOfDelivery = { ...state.redeliverySuborder.delivery };
    delete copyOfDelivery.deliveryAreaName;
    delete copyOfDelivery.windowDescription;
    return copyOfDelivery;
  };

  const formPostDataForRedelivery = () => {
    return {
      ...state.redeliverySuborder,
      delivery: getSanitizedDelivery(),
      reasons: [...state.suborderLevelReasons],
      redeliveryOfSuborderId: suborder.suborderId,
    };
  };

  const getRefundAmountInDollars = () => {
    if (state.refundAmount) return state.refundAmount;
    return getTotalOfCharges();
  };

  const getRefundAmount = () => {
    if (state.refundAmount) return `${state.refundAmount} + tax`;
    return getTotalOfCharges();
  };

  const getTotalOfCharges = () => getAdjustedPaymentTotal({ transactions: order.paymentSummary.transactions });

  const runFunctions = async () => {
    let total = 0;
    if (state.needsRedelivery) {
      total++;
    }
    if (issuingRefund) {
      total++;
    }
    if (state.creditAmountToCustomer > 0 || state.creditAmountToRecipient > 0) {
      total++;
    }
    setTotalNumThatHasToBeProcessed(total);
    setIsProcessingFunctions(true);

    let countForNumBeingProcessed = 0;
    const successes = [];
    const errors = [];

    if (state.needsRedelivery) {
      const redeliveryResponse = await addNewSuborderToOrderForResolution(order.orderId, formPostDataForRedelivery());
      countForNumBeingProcessed++;
      setNumThatHasBeenProcessed(countForNumBeingProcessed);

      if (redeliveryResponse.success === true) {
        successes.push(`Successfully created redelivery for suborder. New suborder: ${redeliveryResponse.newSuborder.suborderId}`);
      } else {
        errors.push(
          `Reached error while trying to redeliver suborder. ${
            redeliveryResponse.message ? `Received the following message: ${redeliveryResponse.message}` : ''}`,
        );
      }
    }
    if (issuingRefund) {
      const amountEqualsGrandTotal =
        order.totals &&
        order.totals.length &&
        order.totals[order.totals.length - 1].orderTotal &&
        order.totals[order.totals.length - 1].orderTotal === getRefundAmountInDollars();
      const amountToSend = amountEqualsGrandTotal ? undefined : getRefundAmountInDollars(); // we'd want to use undefined amount here as the backend expects undefined for full order refunds
      const refundResponse = await refundOrder(order.orderId, amountToSend, createReasonsFromStateVars());
      countForNumBeingProcessed++;
      setNumThatHasBeenProcessed(countForNumBeingProcessed);

      if (refundResponse.success === true) {
        successes.push('Successfully issued refund for suborder.');
      } else {
        errors.push(
          `Reached error while trying to refund suborder. ${refundResponse.message ? `Got the following message: ${refundResponse.message}` : ''}`,
        );
      }
    }
    if (state.creditAmountToCustomer > 0 || state.creditAmountToRecipient > 0) {
      const creditsResponse = await issueCredits(order.orderId, getCreditAmount(), getCreditEmail(), createReasonsFromStateVars());
      countForNumBeingProcessed++;
      setNumThatHasBeenProcessed(countForNumBeingProcessed);

      if (creditsResponse.success === true) {
        successes.push('Successfully issued account credits for suborder.');
      } else {
        errors.push(
          `Reached error while trying to issue credits. ${creditsResponse.message ? `Got the following message: ${creditsResponse.message}` : ''}`,
        );
      }
    }
    if (successes.length > 0) {
      successes.push("Click 'Complete' to return to order.");
    }
    setBackendSuccesses(successes);
    setBackendErrors(errors);
    setIsProcessingFunctions(false);
    setHasProcessedFunctions(true);
    setAllowRectifiation(successes.length === 0 && errors.length > 0);
  };

  return (
    <>
      <h2 className="mb-4 text-center">Summary</h2>
      {(state.creditAmountToCustomer > 0 || state.creditAmountToRecipient > 0) &&
        createCardForRefundOrCredits({
          headerWord: 'Crediting',
          dollarAmount: getCreditAmount(),
          to: getCreditEmail(),
        })}

      {issuingRefund &&
        createCardForRefundOrCredits({
          headerWord: 'Refunding',
          dollarAmount: getRefundAmount(),
          to: getBestTransactionIdentifierForRefund(),
        })}

      {state.needsRedelivery && (
        <div className="row">
          <div className="card">
            <div className="p-3 card-body">
              <div className="row align-items-center">
                <h4>Redelivering:</h4>
                <div className="row">
                  {state.redeliverySuborder.lineItems.map((lineItem) => (
                    <div key={`lineitemkey${lineItem.index}`} className="ml-4">
                      {getLineItemDisplay(lineItem)}
                    </div>
                  ))}
                </div>
                <div className="mt-3 ml-2 row">
                  To:
                  <div className="ml-2">
                    {state.redeliverySuborder.recipient.firstName} {state.redeliverySuborder.recipient.lastName}
                  </div>
                  <div className="ml-2">
                    {state.redeliverySuborder.recipient.address1} {state.redeliverySuborder.recipient.address2}
                  </div>
                  <div className="ml-2">
                    {state.redeliverySuborder.recipient.city} {state.redeliverySuborder.recipient.state} {state.redeliverySuborder.recipient.zipCode}
                  </div>
                </div>
                <div className="mt-3 ml-2 row">
                  Via:
                  <div className="ml-2">{state.redeliverySuborder.delivery.fc.name}</div>
                  <div className="ml-2">{getDeliveryAreaName()}</div>
                </div>
                <div className="mt-3 ml-2 row">
                  On:
                  <div className="ml-2">{getDeliveryStartDate()}</div>
                  <div className="ml-2">{getDelivery().windowDescription}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.lineItemsWithIssue && state.lineItemsWithIssue.length > 0 && (
        <div className="row">
          <div className="card">
            <div className="p-3 card-body">
              <div className="row align-items-center">
                <h4>For:</h4>
                {state.lineItemsWithIssue.map((lineItem, liIndex) => (
                  <div className={liIndex > 0 ? 'row ml-2 mt-3' : 'row ml-2'} key={`issue-${liIndex}`}>
                    <div className="row">
                      <span className="font-weight-bold">{getLineItemDisplay(lineItem)}</span>
                    </div>
                    {lineItem.userActionReasons &&
                      lineItem.userActionReasons
                        .filter((uaReason) => !uaReason.createdAt)
                        .map((reason, rIndex) => (
                          <div className="ml-2 row" key={`issue-${liIndex}-${rIndex}`}>
                            {printReason(reason)}
                          </div>
                        ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {state.suborderLevelReasons && state.suborderLevelReasons.length > 0 && (
        <div className="row">
          <div className="card">
            <div className="p-3 card-body">
              <div className="row align-items-center">
                <h4>For:</h4>
                <div>
                  <span className="font-weight-bold">Suborder {suborder.suborderId}</span>
                </div>
                {state.suborderLevelReasons.map((reason, rIndex) => (
                  <div className="ml-2 row" key={`issue-${rIndex}`}>
                    {printReason(reason)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {(isProcessingFunctions || hasProcessedFunctions) && (
        <div className="row">
          <div className="card">
            <div className="card-body p3">
              <div className="row align-items-center">
                <h4>{isProcessingFunctions ? 'Processing...' : 'Redelivery Processed'}</h4>
                {totalNumThatHasToBeProcessed > 0 && (
                  <Progress percentage={Math.ceil(numThatHasBeenProcessed / totalNumThatHasToBeProcessed) * 100} />
                )}
                {backendSuccesses.length > 0 && (
                  <div className="mt-2 row">
                    {backendSuccesses.map((success, index) => (
                      <div className="ml-4 row text-success" key={`success-${index}`}>
                        {success}
                      </div>
                    ))}
                  </div>
                )}
                {backendErrors.length > 0 && (
                  <div className="mt-2 row">
                    {backendErrors.map((error, index) => (
                      <div className="ml-4 row text-danger" key={`error-${index}`}>
                        {error}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {hasProcessedFunctions && !allowRectification && (
        <Footer backActionText="Back" backAction={props.previousStep} forwardActionText="Complete" forwardAction={closeModal} disableBackOn />
      )}
      {(!hasProcessedFunctions || allowRectification) && (
        <Footer
          backActionText="Back"
          backAction={props.previousStep}
          forwardActionText="Submit"
          forwardAction={runFunctions}
          disableForwardOn={isProcessingFunctions}
        />
      )}
    </>
  );
};

export default Summary;
